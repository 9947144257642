



































































import { Component, Vue } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {},
})
export default class home_movil extends Vue {
  public dialogNewVersion: boolean = false;
  public height = (30 * this.$vuetify.breakpoint.height) / 100;
  public created() {}
  public mounted() {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }
  public get buttons1() {
    return [
      {
        css: "m-gradient-16",
        icon: "fad fa-ticket-alt fa-3x",
        title: "Incidencias",
        style: "height:" + this.height + "px;",
        namelink: RouterNames.todas_incidencias_lista_movil,
      },
      {
        css: "m-gradient-primary",
        color: "#009BD2",
        icon: "fad fa-user fa-3x",
        title: "Mi perfil",
        style: "height:" + this.height + "px;",
        namelink: RouterNames.mi_perfil,
      },
    ];
  }

  public get buttons2() {
    return [
      {
        css: "m-gradient-14",
        icon: "fad fa-inventory fa-3x",
        title: "Inventario",
        style: "height:" + this.height + "px;",
        namelink: RouterNames.inventario_treeview,
      },
      {
        css: "m-gradient-15",
        icon: "fad fa-ballot-check fa-3x",
        title: "Tareas",
        style: "height:" + this.height + "px;",
        namelink: RouterNames.todas_tareas_movil,
      },
    ];
  }

  public goto(name: string) {
    this.$router.push({ name: name });
  }
}
